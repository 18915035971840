import React from 'react'
import ReactDOM from 'react-dom/client'
import SearchIcon from '@mui/icons-material/Search';

import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'

import './index.css'

import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'

//

import { fetchData, Person } from './fetchData'
import { Box, FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { getUrl } from '../auth/getUrl'
import { json } from 'stream/consumers'
import { useNavigate } from 'react-router-dom';

const queryClient = new QueryClient()


function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}



export function ServerStatusPage() {
  const rerender = React.useReducer(() => ({}), {})[1]

  const columns = React.useMemo<ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'Id',
        header: 'ID',
        size: 60,
      },

      {
        accessorKey: 'Domain',
        header: 'Домен',
        size: 150,
      },
      {
        accessorKey: 'Port',
        header: () => 'Порт',
        size: 50,
      },
      {
        accessorKey: 'Count',
        header: "Число пользователей",
        cell: info => {
          if (info.getValue() == 999) {
            return "Ошибка в получении данных"
          }
          else {
            return info.getValue()
          }
        },
        size: 50,
      },
      {
        accessorKey: 'Country',
        header: 'Страна',
        meta: {
          filterVariant: 'range',
        },
      },
      {
        accessorKey: 'Type',
        header: 'Тип',
        size: 80,
      },
      {
        accessorKey: 'Description',
        header: 'Описание',
        cell: info => info.getValue<Date>().toLocaleString(),
        size: 200,
      },
    ],
    []
  )

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
    filter: null,
    search: ""
  })

  const [columnFilters, setColumnFilters] = React.useState<any>(
    []
  )


  const dataQuery = useQuery({

    queryKey: ['data', pagination],
    queryFn: () => fetchData(pagination),
    //placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
  })

  const defaultData = React.useMemo(() => [], [])

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    filterFns: {},
    onColumnFiltersChange: setColumnFilters,
    columns,
    // pageCount: dataQuery.data?.pageCount ?? -1, //you can now pass in `rowCount` instead of pageCount and `pageCount` will be calculated internally (new in v8.13.0)
    rowCount: dataQuery.data?.rowCount, // new in v8.13.0 - alternatively, just pass in `pageCount` directly
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true, //we're doing manual "server-side" pagination
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  })

  const [globalFilter, setGlobalFilter] = React.useState('')
  const [coutries, setCountries] = React.useState([])
  const [types, setTypes] = React.useState([])
  const [currentCountry, setCurrentCountry] = React.useState<string>()
  const [currentType, setCurrentType] = React.useState<string>()

  const [searchString, setSearchString] = React.useState<string>()

  React.useEffect(() => {

    getUrl(`/api/countries`)
      /*  fetch(process.env.REACT_APP_TITLE + `/transaction?page=0&size=${size}`, {
         method: 'GET',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         credentials: "include"
       }) */
      .then(response => {


        return response.json()
      })
      .then(data => {

        setCountries(data)
      },
        (error) => {

          //setIsLoaded(true);
          //setError(error);
        }
      )

    getUrl(`/api/server_types`)
      /*  fetch(process.env.REACT_APP_TITLE + `/transaction?page=0&size=${size}`, {
         method: 'GET',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         credentials: "include"
       }) */
      .then(response => {


        return response.json()
      })
      .then(data => {

        setTypes(data)
      },
        (error) => {

          //setIsLoaded(true);
          //setError(error);
        }
      )

      ;
  }, [])


  const handleChange = (event: SelectChangeEvent) => {
    setCurrentCountry(event.target.value as string);

     
    var p = pagination;

    p.filter = JSON.stringify({ pageIndex: 0, counry: event.target.value, type: currentType })
    p.search = searchString
    setPagination(p)
    fetchData(pagination)
  };

  const handleChange_type = (event: SelectChangeEvent) => {
    setCurrentType(event.target.value as string);

    
    var p = pagination;

    p.filter = JSON.stringify({ pageIndex: 0, type: event.target.value, counry: currentCountry})
    p.search = searchString
    setPagination(p)
    fetchData(pagination)
  };

  const changeUserId = (event: React.ChangeEvent<HTMLInputElement>) => {
    

    setSearchString(event.target.value.trim());
  }


  const handleSearchChange = async () => {

debugger
    
    var p = pagination;

    p.filter = JSON.stringify({ pageIndex: 0, type: currentType, counry: currentCountry })
    p.search = searchString
    setPagination(p)
    var y = await fetchData(pagination)
  };


  const navigate = useNavigate();

  
  return (
    <div className="p-2">
      <div className="h-2" />
      <Box sx={{ p: 4 }}>


        <Box sx={{ pb: 2 }}>
          <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
          >

            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Домен или IP"
              /* inputProps={{ 'aria-label': 'search google maps' }} */
              onChange={changeUserId}
             /*  onKeyDown={(e: any) => {

                changeUserId(e)
              }} */
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={()=>{handleSearchChange()}} >
              <SearchIcon />
            </IconButton>
            {/*  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
            {/*    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                    <DirectionsIcon />
                </IconButton> */}
          </Paper>

        </Box>

        <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body1">
                Страна
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  /*  value={age} */
                  label=""
                  onChange={handleChange}

                >



                  <MenuItem value={""}>Все</MenuItem>

                  {
                    coutries.map((x: any) => {
                      return <MenuItem value={x.Flag}>{x.Country}</MenuItem>
                    })
                  }

                </Select>
              </FormControl>
            </Grid>

          </Grid>



        </Box>


        <Box mt={2} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body1">
                Тип
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  /*  value={age} */
                  label=""
                  onChange={handleChange_type}

                >



                  <MenuItem value={""}>Все</MenuItem>
                  {
                    types.map((x: any) => {
                      return <MenuItem value={x.Id}>{x.Desc}</MenuItem>
                    })
                  }
                  {/* {
                coutries.map((x: any) => {
                  return <MenuItem value={x.Flag}>{x.Country}</MenuItem>
                })
              } */}

                </Select>
              </FormControl>
            </Grid>

          </Grid>



        </Box>



      </Box>
      <Box>
        {dataQuery.isFetching ? 'Loading...' : <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>}
      </Box>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          {/*       <div>Page</div> */}
          <strong>
            {/*    {table.getState().pagination.pageIndex + 1} of{' '} */}
            {/* {table.getPageCount().toLocaleString()} */}
          </strong>
        </span>
        {/*  <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            min="1"
            max={table.getPageCount()}
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span> */}
        {/*  <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}

      </div>

    </div>
  )
}

