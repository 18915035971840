import * as React from 'react';
import { getUrl } from '../../auth/getUrl';
import { useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Line } from 'react-chartjs-2';
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { format } from "date-fns";

import { LineChart } from '@mui/x-charts/LineChart';
import { Block } from "@mui/icons-material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from "dayjs";
import { useNavigate } from 'react-router-dom';



export function PcPayInfo(props: any) {
    const navigate = useNavigate();
    const [payInfoPcData, setPayInfoPcData] = useState<any>({});
    const [payInfoPc, setPayInfoPc] = useState<any>({});
    React.useEffect(() => {
       
    getUrl(`/api/payment_info_pc`)
        .then(response => {
            return response.json()
        })
        .then(data => {

            setPayInfoPc(data)
        }, error => {
            navigate("/login")
        }
        );


  

    getUrl(`/api/payment_info_pc_data`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setPayInfoPcData(data)
        }, error => {
            navigate("/login")
        }
        );


    }, [])


    React.useEffect(() => {
        getUrl(`/api/payment_info_pc`)
            .then(response => {
                return response.json()
            })
            .then(data => {

                setPayInfoPc(data)
            }, error => {
                navigate("/login")
            }
            );





        getUrl(`/api/payment_info_pc_data?dateStart=`+props.dateStart+"&dateEnd="+props.dateEnd+"&counter="+props.counter)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setPayInfoPcData(data)
            }, error => {
                navigate("/login")
            }
            );



    }, [props.dateStart, props.dateEnd, props.counter])

    return (
        <Box>
        <hr />
        <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10, color: '#61dafb' }} gutterBottom>
            Кол-во подключений (PC) <b> - {payInfoPc?.ConnCount}</b>
        </Typography>


    {/*     {
            payInfoPcData != null && payInfoPcData.length > 0
                ?
                <LineChart
                    xAxis={[{
                        scaleType: 'point', data: payInfoPcData?.map((x: any) => {
                            var a = new Date(x.Data).toISOString().
                                replace(/T/, ' ').      // replace T with a space
                                replace(/\..+/, '')

                            
                            return a

                        })
                    }]}
                    series={[
                        {
                            data: payInfoPcData?.map((x: any) => x.ConnCount),

                            color: '#00c853'
                        },
                    ]}

                    height={300}
                />
                : <></>
        } */}



        <Typography variant="subtitle2" style={{ display: "flex", paddingBottom: 10, color: '#61dafb' }} gutterBottom>
            Кол-во уникальных пользователей  (PC)<b> - {payInfoPc?.UserCount}</b>
        </Typography>

       {/*  {
            payInfoPcData != null && payInfoPcData.length > 0
                ?
                <LineChart
                    xAxis={[{
                        scaleType: 'point', data: payInfoPcData?.map((x: any) => {
                            var a = new Date(x.Data).toISOString().
                                replace(/T/, ' ').      // replace T with a space
                                replace(/\..+/, '')

                           
                            return a

                        })
                    }]}
                    series={[
                        {
                            data: payInfoPcData?.map((x: any) => x.UserCount),

                            color: '#00c853'
                        },
                    ]}

                    height={300}
                />
                : <></>
        } */}

    </Box>

    );
}
