import React, { useEffect, useState } from 'react';
/* import logo from './logo.svg'; */
import './App.css';
import TransactionTable from './transactions/TransactionTable';
import Login from './auth/Login';
import { getToken } from './services/token';
import { Box, Button, Container, Tab, Tabs, Typography } from '@mui/material';
import TransactionTab from './transactions/TransactionTab';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,

  useRoutes
} from "react-router-dom";
import NavBar from './NavBar';
import TransactionStatistic from './transactions/TransactionStatistic';
import ServerTable from './Servers/Server';
import CodeList from './codelist/CodeList';
import User from './codelist/Usesr';
import { Provider, connect } from 'react-redux';
import configureStore from './redux/stores/store';
import NewCode from './codelist/NewCode';
import MovingServers from './MovingServers/MovingServers';
import { ServerStatusPage } from './Servers/ServerStatusPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';



function AppRoutes(props: any) {

  var auth = true//localStorage.getItem("token");;

  const routes = useRoutes(
    [
      { path: '/transactions', element: <TransactionTable /> },
      { path: '/login', element: auth ? <Login /> : <Login /> },
      { path: '/graphs', element: <TransactionStatistic /> },
      { path: '/servers', element: <ServerStatusPage /> },
      { path: '/statics', element: <ServerTable /> },
      { path: '/lk/:id', element: <CodeList /> },
      { path: '/lk', element: <CodeList /> },
      { path: '/new', element: <NewCode /> },
      { path: '/user/:id', element: <User /> },
      { path: '/moving_servers', element: <MovingServers /> },
      { path: '/', element: <TransactionTable /> },
    ]
  )
  return routes;
}


function App() {


  React.useEffect(() => {


    var res = getToken().then((r: any) => {


      if (r != null)
        setToken(true)
      let parsed = JSON.parse(r)
      // setRole(parsed.Role)
    })

  }, []);

  const queryClient = new QueryClient()

  const [token, setToken] = useState(false);
  const [role, setRole] = useState(-1);

  var onHelpClick = () => {
    window.open(
      "https://t.me/whypnbot/account", '_blank'
    );
  }

  /*   if (!token) {
      return (
        <Login setToken={setToken}></Login>
      )
    }
    else { */
  return (
    /*   <div className="App">
        <Container maxWidth="lg" sx={{mt:2}}>
          <Box sx={{ p: 2, background: 'white', borderRadius: '12px', textAlign: 'center' }} className='test_2' >
            <Typography  sx={{ color: 'black', mb:2 }} >Для работы с WhyPN воспользуйтесь новой версией, встроенной в экосистему Telegram ✈️</Typography>
  
  
  
            <Button className='pay_button' variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%' }}
              style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onHelpClick}>
             Подключиться
  
            </Button >
          </Box>
        </Container>
      </div> */
    <QueryClientProvider client={queryClient}>
      <Provider store={configureStore()}>
        <Router  >
          <div className="App">
            <Container maxWidth="lg">
              <NavBar ></NavBar>

              <AppRoutes ></AppRoutes>
            </Container>
          </div>
        </Router>
      </Provider>
    </QueryClientProvider>
  )
}

export default (App);
