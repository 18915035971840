import { faker } from '@faker-js/faker'
import { getUrl } from '../auth/getUrl'

export type Person = {
  firstName: string
  lastName: string
  age: number
  visits: number
  progress: number
  status: 'relationship' | 'complicated' | 'single'
  subRows?: Person[]
}

const range = (len: number) => {
  const arr: number[] = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

const newPerson = (): Person => {
  return {
    firstName: faker.person.firstName(),
    lastName: faker.person.lastName(),
    age: faker.number.int(40),
    visits: faker.number.int(1000),
    progress: faker.number.int(100),
    status: faker.helpers.shuffle<Person['status']>([
      'relationship',
      'complicated',
      'single',
    ])[0]!,
  }
}

export function makeData(...lens: number[]) {
  const makeDataLevel = (depth = 0): Person[] => {
    const len = lens[depth]!
    return range(len).map((_d): Person => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      }
    })
  }

  return makeDataLevel()
}

const data = makeData(10000)

export async function fetchData(options: {
  pageIndex: number
  pageSize: number,
  filter:any,
  search:any
}) {
  // Simulate some network latency
/*   await new Promise(r => setTimeout(r, 500)) */

var urlStr = `/api/servers_status?page=`+ (options.pageIndex)+`&size=${options.pageSize}`

if (options.filter != null) { 
   
  urlStr += `&filter=${options.filter}`
}

 
if (options.search != null) { 
   
  urlStr += `&search=${options.search}`
}

var d = await    getUrl(urlStr)
var d_ = await  d.json()

debugger
  return {
    rows: d_,
    pageCount: Math.ceil(data.length / options.pageSize),
    rowCount: data.length,
  }
}
