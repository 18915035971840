import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CssBaseline, Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function NavBar(props: any) {


    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [role, setRole] = React.useState<any>(1);


    const navigate = useNavigate();


    React.useEffect(() => {

        setRole(props.counter.rotating)

        /* 
            var res = getToken().then((r: any) => {
              
               
              if (r != null)
                setToken(true)
                let parsed = JSON.parse(r)
               // setRole(parsed.Role)
            }) */

    }, [props.counter]);

    const drawerWidth = 240;
    const navItems = ['Home', 'About', 'Contact'];

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign:'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                WhyPN // Admin
            </Typography>
            <Divider />
            <List>

                <ListItem key={"transactions"} disablePadding>
                    <ListItemButton sx={{ textAlign: 'left' }} onClick={() => navigate("/transactions")}>
                        <ListItemText primary={"Транзакции"} />
                    </ListItemButton>
                </ListItem>

                {role == 2 ?
                    <ListItem key={"graphs"} disablePadding>
                        <ListItemButton sx={{ textAlign: 'left' }} onClick={() => navigate("/graphs")}>
                            <ListItemText primary={"Графики"} />
                        </ListItemButton>
                    </ListItem>

                    : <></>
                }


                <ListItem key={"servers"} disablePadding>
                    <ListItemButton sx={{ textAlign: 'left' }} onClick={() => navigate("/servers")}>
                        <ListItemText primary={"Сервера"} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={"lk"} disablePadding>
                    <ListItemButton sx={{ textAlign: 'left' }} onClick={() => navigate("/lk")}>
                        <ListItemText primary={"Личный кабинет"} />
                    </ListItemButton>
                </ListItem>

                {role == 2 ?
                    <ListItem key={"moving_servers"} disablePadding>
                        <ListItemButton sx={{ textAlign: 'left' }} onClick={() => navigate("/moving_servers")}>
                            <ListItemText primary={"Переезд серверов"} />
                        </ListItemButton>
                    </ListItem>

                    : <></>
                }


                {/*  {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))} */}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="static"  component="nav" sx={{background:'black'}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ textAlign: 'left', flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                         WhyPN // Admin
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Button

                            onClick={() => navigate("/transactions")}
                            sx={{ color: '#fff' }}
                        >
                            Транзакции
                        </Button>


                        {role == 2 ?
                            <Button

                                onClick={() => navigate("/graphs")}
                                sx={{ color: '#fff' }}
                            >
                                Графики
                            </Button>
                            : <></>
                        }

                        <Button

                            onClick={() => navigate("/servers")}
                            sx={{ color: '#fff' }}
                        >
                            Сервера
                        </Button>


                        <Button

                            onClick={() => navigate("/lk")}
                            sx={{ color: '#fff' }}
                        >
                            ЛК пользователя
                        </Button>



                        {role == 2 ?
                            <Button

                                onClick={() => navigate("/moving_servers")}
                                sx={{ color: '#fff' }}
                            >
                                Переезд серверов
                            </Button>
                            : <></>
                        }


                        {/*    {navItems.map((item) => (
                            <Button key={item} sx={{ color: '#fff' }}>
                                {item}
                            </Button>
                        ))} */}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>

        </Box>
    );
}

const mapStateToProps = (state: any) => {

    return {
        counter: state
    };
};


export default connect(mapStateToProps)(NavBar);